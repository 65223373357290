import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

function PrivacyPolicy() {
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="Learn how we collect, use, and protect your personal information on our Privacy Policy page. Stay informed about your data rights, security measures, and compliance with privacy regulations."
      />
      <div className="text-justify">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h1>Privacy Policy</h1>
              </div>
              <div className="content-section">
                <p>
                  <i>Last Updated: November 2024</i>
                </p>
                <p><strong>D2i Technology</strong> (“us”, “we”, “our”, or “Company”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website (<a href="/">d2itechnology.com</a>) and our software development, DevOps, and accessibility testing services (collectively referred to as <strong>“Services”</strong>).</p>
                <p>Please read this Privacy Policy carefully. By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by all terms of this Privacy Policy. If you do not agree with our policies and practices, please do not use our Services.</p>
                
                <h2 className="cloudhead h3">Information We Collect</h2>
                <h3 className="cloudhead h4">Personal Information</h3>
                <p>We may collect the following types of personally identifiable information: - Contact information (name, email address, phone number, postal address) - Business information (company name, job title, department) - Account credentials (username, password) - Project-related information - Payment information - Communication preferences</p>
                <h3 className="cloudhead h4">Technical Information</h3>
                <p>When you use our Services, we automatically collect: - Log Data (IP address, browser type and version, time zone setting, operating system) - Usage Data (pages visited, time spent on pages, interaction patterns) - Device Information (device type, mobile device identifiers) - Performance Data (system metrics, error reports)</p>
                <h3 className="cloudhead h4">Cookies and Similar Technologies</h3>
                <p>We use cookies, web beacons, and similar tracking technologies to: - Maintain your session and preferences - Analyze usage patterns and trends - Personalize your experience - Improve our Services - Support security features</p>
                <p>You can modify your browser settings to decline cookies, but this may affect certain features of our Services.</p>
                
                <h2 className="cloudhead h3">How We Use Your Information</h2>
                <p>We use collected information for:</p>
                <h3 className="cloudhead h4">Business Operations</h3>
                <ul>
                    <li>Providing and maintaining our Services</li>
                    <li>Processing and fulfilling service requests</li>
                    <li>Managing customer relationships</li>
                    <li>Communicating about projects and services</li>
                    <li>Sending administrative information</li>
                </ul>
                <h3 className="cloudhead h4">Service Improvement</h3>
                <ul>
                    <li>Analyzing and improving our Services</li>
                    <li>Developing new features and functionality</li>
                    <li>Conducting research and analysis</li>
                    <li>Troubleshooting technical issues</li>
                </ul>
                <h3 className="cloudhead h4">Marketing and Communication</h3>
                <ul>
                    <li>Sending newsletters and updates</li>
                    <li>Providing promotional offers</li>
                    <li>Conducting surveys and feedback collection</li>
                    <li>Marketing our Services</li>
                </ul>
                <p>You may opt out of marketing communications at any time by clicking the “unsubscribe” link in our emails or contacting us directly.</p>

                <h2 className="cloudhead h3">Information Sharing and Disclosure</h2>
                <h3 className="cloudhead h4">Service Providers</h3>
                <p>We may share your information with third-party vendors who assist us in: - Hosting and maintaining our Services - Processing payments - Analyzing data - Providing customer support - Marketing and email delivery - Security and fraud prevention</p>
                <p>All service providers are contractually obligated to use your information only to provide services to us and in accordance with this Privacy Policy.</p>
                <h3 className="cloudhead h4">Legal Requirements</h3>
                <p>We may disclose your information if required by law, regulation, legal process, or governmental request.</p>
                <h3 className="cloudhead h4">Business Transfers</h3>
                <p>If we are involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</p>
                
                <h2 className="cloudhead h3">Data Security</h2>
                <p>We implement appropriate technical and organizational security measures to protect your information, including: - Encryption of data in transit and at rest - Access controls and authentication - Regular security assessments - Employee training on data protection - Incident response procedures</p>
                <p>However, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your information.</p>

                <h2 className="cloudhead h3">International Data Transfers</h2>
                <p>We may transfer your information to countries other than your country of residence. When we do so, we ensure appropriate safeguards are in place to protect your information and comply with applicable data protection laws.</p>

                <h2 className="cloudhead h3">Your Rights and Choices</h2>
                <p>Depending on your location, you may have certain rights regarding your personal information: - Access your personal information - Correct inaccurate information - Request deletion of your information - Object to or restrict processing - Data portability - Withdraw consent</p>
                <p>To exercise these rights, please contact us using the information provided below.</p>

                <h2 className="cloudhead h3">Children’s Privacy</h2>
                <p>Our Services are not intended for children under 14 years of age. We do not knowingly collect or solicit personal information from children. If we learn we have collected personal information from a child without parental consent, we will delete that information promptly.</p>

                <h2 className="cloudhead h3">Third-Party Links</h2>
                <p>Our Services may contain links to third-party websites and services. We are not responsible for these third parties' privacy practices or content. We encourage you to review their privacy policies before providing them with any personal information.</p>

                <h2 className="cloudhead h3">Changes to This Policy</h2>
                <p>We reserve the right to update this Privacy Policy at any time. We will notify you of any material changes by posting the updated Privacy Policy on this page and updating the “Last Updated” date. Your continued use of our Services after such modifications constitutes your acknowledgment of the modified Privacy Policy.</p>

                <h2 className="cloudhead h3">Contact Us</h2>
                <p>If you have questions, concerns, or requests related to this Privacy Policy or our privacy practices, please contact us at <a href="mailto:info@d2itechnology.com">info@d2itechnology.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
